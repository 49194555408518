@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

$icon-width: 22px;
$icon-height: 22px;

.wrapper {
    @include mixins.space-vertical(9px);

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 $icon-width;
    gap: 5px;

    img {
        cursor: pointer;
        width: $icon-width;
        height: $icon-height;
        margin: 0;
    }
}
