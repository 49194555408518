@use '../../../../styles/theme';
@use '../../../../styles/mixins';

.content {
    .body {
        @include mixins.space-vertical(20px);
        padding: 18px 24px 40px;
    }

    .linked {
        margin-top: 5px;
    }

    input.input {
        padding: 6px 36px 6px 12px;
    }

    textarea.input {
        height: 144px;
    }
}

.footer {
    composes: footer from '../../Modals.module.scss';
}

.showMore {
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    color: theme.$text-light-gray;
    white-space: nowrap;
    margin-inline-start: 5px;
}
