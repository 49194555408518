@use '../../../styles/theme';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.link {
    cursor: pointer;
    font-size: 14px;
    color: theme.$surface-primary;
    text-decoration: underline;

    &:hover {
        color: theme.$surface-primary;
        text-decoration: none;
    }
}
