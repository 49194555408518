@use '../../../../../styles/mixins';

@value footer as previewModalFooter from "../../../../Modals/Preview/Footer/PreviewModalFooter.module.scss";

.public {
    @include mixins.thumb-icon(16px);

    .previewModalFooter & {
        @include mixins.thumb-icon(20px);
    }
}

.private {
    @include mixins.thumb-icon(19px);
    margin-top: -5px;

    .previewModalFooter & {
        @include mixins.thumb-icon(20px);
    }
}

.tooltip {
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
}
