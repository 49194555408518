@use '../../styles/theme';
@use '../../styles/mixins';

.wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    gap: 8px;
}
