.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fcfcfc;
}

.confirmContainer {
    &#{&} {
        margin-top: 10em;
    }
}

.inner {
    min-height: 0;
    height: 100%;
    padding: 8px 8px 0;
    flex: 1;
}

.controls {
    flex: 0;
    margin-top: auto;

    button {
        height: 100%;
        min-height: 32px;
        min-width: 120px;
        padding: 0;
        font-size: 1.2em;
        font-weight: 500;
        border-radius: 4px;
    }
}
