@use '../../../../styles/theme';
@use '../../../../styles/mixins';

%extendableItemWrapper {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 8px;
}

.list {
    @include mixins.space-vertical(4px);
    list-style: none;
    margin: 0;
    padding: 0 16px 0 16px;
}

.listItem {
    @extend %extendableItemWrapper;

    &:hover {
        .actionSelectOnly {
            display: block;
        }
    }
}

.listWrapper {
    padding: 8px 0 0 8px;
}

.actionSelectOnly {
    display: none;

    &:hover {
        display: block;
    }
}

.checkBox {
    width: 100%;
    padding: 8px 0;
}

.action {
    flex: 0 0 auto;
    background: none;
    border: unset;
    appearance: none;
    color: theme.$link-primary;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    font-size: 12px;
    font-weight: 600;

    &:hover {
        color: theme.$hover-blue;
    }

    &:active {
        color: theme.$link-hover-light-blue;
    }
}

.selectedCount {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 16px;
    margin-inline-start: 6px;
}

.selectedCountBold {
    color: theme.$blue-gray-900;
    font-weight: 700;
}
