@use '../../../styles/theme';

.wrapper {
    width: 100%;
    height: 100%;
    padding-block: 32px;
}

.container {
    width: 100%;
    height: 100%;
    max-width: 260px;
    min-width: 0;
    min-height: 0;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-inline: auto;
}

.icon {
    width: 68px;
    aspect-ratio: 1;
    margin-inline: auto;
    color: theme.$text-light-gray;
}

%text {
    width: 100%;
    letter-spacing: 0.15px;
    text-align: center;
}

.heading {
    @extend %text;

    font-size: 1.8em;
    font-weight: 500;
    line-height: 1.33;
    color: theme.$text-positive;
}

.description {
    @extend %text;

    font-size: 1.6em;
    font-weight: 400;
    line-height: 1.5;
    color: theme.$text-mid-gray;
}
