@use 'answers-core/src/styles/mixins';

.comment {
    height: 32px;
    padding: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    @include mixins.thumb-icon(20px);
}

.commentForm {
    width: 100%;
    border-radius: 4px;
    background-color: #90caf9;
    padding: 32px 3px 3px;
    box-shadow: 0 4px 15px 0 #0000000d, 0 1px 2px 0 #0000000d;
}
