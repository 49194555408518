@use '../../../../../styles/theme';
@use '../../../../../styles/mixins';

$image-width: 15px;
$image-height: 15px;

.wrapper {
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    align-items: center;
}

.text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: theme.$blue-gray-600;
}

.textBold {
    font-weight: 500;
    color: theme.$blue-gray-900;
}

.icon {
    width: 24px;
    height: 24px;
    aspect-ratio: 1;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    margin: auto;
    display: block;
    width: 100%;
    height: auto;
    max-width: $image-width;
    max-height: $image-height;
    object-fit: cover;
}

.listItem {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 8px;

    &:hover {
        .actionSelectOnly {
            display: block;
        }
    }
}

.actionSelectOnly {
    display: none;

    &:hover {
        display: block;
    }
}

.itemWrapper {
    width: 100%;
    padding: 8px 0;
    overflow: hidden;
}

.action {
    flex: 0 0 auto;
    background: none;
    border: unset;
    appearance: none;
    color: theme.$link-primary;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    font-size: 12px;
    font-weight: 600;

    &:hover {
        color: theme.$hover-blue;
    }

    &:active {
        color: theme.$link-hover-light-blue;
    }
}

.disabledCheckbox {
    opacity: 50%;
}

:export {
    imageWidth: $image-width;
    imageHeight: $image-height;
}
