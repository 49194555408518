@use '../../../../styles/theme';
@use '../../../../styles/mixins';

$pointer-width: 35px;
$pointer-height: 38px;

.wrapper {
    display: flex;
    align-content: center;
    justify-content: center;
    position: relative;
    outline: none;

    &:global(.chakra-modal__body) {
        padding: 0;
    }

    iframe {
        width: 100%;
        flex: 1 1 auto;
    }

    .dimmer {
        @include mixins.dimmer($height: 32px);
    }
}

.spinner {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
}

.disableEvents {
    pointer-events: none;
}

.pdfViewerWrapper {
    width: 100%;
    height: 100%;
}
