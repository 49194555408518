@use '../../../styles/theme';
@use '../../../styles/mixins';

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.version {
    color: theme.$text-light-gray;
    font-weight: 500;
    font-size: 0.7em;
    line-height: 24px;
}

.body {
    @include mixins.space-vertical(10px);

    display: flex;
    flex-direction: column;
}

.footer {
    composes: footer from '../Modals.module.scss';
}

.checkboxWrapper {
    display: flex;
    gap: 11px;

    div {
        user-select: none;

        &:hover {
            cursor: pointer;
        }

        .label {
            font-size: 1.4em;
            font-weight: 500;
        }

        .label:nth-child(1) {
            color: theme.$text-control;
            margin-bottom: 2px;
        }

        .label:nth-child(2) {
            color: theme.$text-light-gray;
        }
    }
}
