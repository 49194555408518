.contentWithScale {
    .body {
        padding: 18px 24px 24px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .textarea {
        padding: 6px 12px;
    }

    .warning {
        display: flex;
        justify-content: space-between;
    }
}
