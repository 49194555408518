@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

.wrapper {
    @include mixins.space-vertical(8px);

    padding: 16px;
}

.annotateList {
    @include mixins.space-vertical(8px);
    position: relative;
}

.spinner {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
}

.placeholder {
    &:not(:first-child) {
        margin-top: 24px;
    }
}
