@use '../../styles/mixins';

:global(.chakra-modal__footer).footer {
    @include mixins.space(24px);

    justify-content: center;
}

:global(.chakra-modal__content-container:has([class*='contentWithScale'])) {
    scale: 1;
}
