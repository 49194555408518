@use '../../../styles/mixins';

.body {
    padding: 8px;
}

.footer {
    @include mixins.space(8px);

    justify-content: flex-end;
}
