@use '../../styles/theme';
@use '../../styles/mixins';

.wrapper {
    display: flex;
    align-items: flex-end;

    font-size: 1.2em;
    line-height: 15px;
    font-weight: 500;
    color: theme.$text-light-gray;
    gap: 15px;
}

.left {
    display: flex;
    flex: 1;
    align-items: flex-end;
    min-height: 30px;
    overflow: hidden;
}

.right {
    @include mixins.space(20px);

    display: flex;
    flex-direction: row-reverse;
}
