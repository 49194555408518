@use '../../../../styles/theme';
@use '../../../../styles/mixins';

.body {
    @include mixins.space-vertical(20px);
}

.footer {
    composes: footer from '../../Modals.module.scss';
}
