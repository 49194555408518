@use '../../../styles/theme';
@use '../../../styles/mixins';

.footer {
    composes: footer from '../Modals.module.scss';
}

.content {
    .body {
        padding: 18px 24px 104px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .textarea {
        padding: 6px 12px;
    }
}

.showMore {
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    color: theme.$text-light-gray;
    white-space: nowrap;
    margin-inline-start: 5px;
}
