@use '../../styles/theme';
@use '../../styles/mixins';

.wrapper {
    @include mixins.space(11px);

    display: flex;
    align-items: center;
    white-space: nowrap;
}

.button {
    cursor: pointer;
}
