@use '../../../styles/theme';
@use '../../../styles/mixins';

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.body {
    @include mixins.space-vertical(10px);

    display: flex;
    flex-direction: column;
}

.inputWrapper {
    padding: 8px 12px 16px;

    input::placeholder {
        color: theme.$text-light-gray;
    }
}

.footer {
    composes: footer from '../Modals.module.scss';
}

.list {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.listItem {
    @include mixins.overflow-ellipsis();

    position: relative;
    padding: 12px 36px 12px 0;
    font-size: 1.4em;
    line-height: 24px;
    border-bottom: 1px solid theme.$border-light-gray;

    &:last-child {
        border: unset;
    }
}

.removeButton {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 2px;
    background: none;
    border: unset;
    appearance: none;
    color: theme.$text-mid-gray;

    &:hover {
        color: theme.$text-dark-gray;
        cursor: pointer;
    }
}

:export {
    addButtonColor: theme.$link-primary;
}
