@use '../../../styles/theme';
@use '../../../styles/mixins';

.wrapper {
    @include mixins.space(10px);

    display: flex;
    align-items: center;
    padding: 0 16px;
}

.avatar {
    width: theme.$avatar-size-large;
    height: theme.$avatar-size-large;

    font-weight: 500;
    font-size: 1.2em;
    line-height: 17px;
}

.details {
    @include mixins.space-vertical(2px);

    display: flex;
    flex-direction: column;

    color: rgba(26, 26, 26, 0.5);
    font-size: 1.2em;
    line-height: 20px;
    font-weight: 500;
}

.name {
    color: #1a1a1a;
    font-weight: 700;
    text-transform: capitalize;
}
