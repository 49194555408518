@use 'answers-core/src/styles/mixins';
@use 'answers-core/src/styles/theme';

.button {
    display: inline-flex;
    padding: 4px;
    border-radius: 3px;
    min-height: 24px;
    min-width: 116px;
    color: theme.$text-light-gray;
    background-color: transparent;
    transition: background-color, color 0.3s ease-in;
    cursor: pointer;
    font-size: 1.1em;
    font-weight: 500;
}

.buttonContent {
    @include mixins.space(4px);

    display: flex;
    align-items: center;
}

.open,
.button:hover {
    color: theme.$action-button-color--hover;
    background-color: theme.$button-ghost--hover;
}

.buttonIcon {
    transition: transform ease-in-out 0.2s;
}

.button.open .buttonIcon {
    transform: rotate(-180deg);
}
