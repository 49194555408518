:export {
    previewHighlightBackground: rgba(38, 132, 255, 0.2);
}

.spinner {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
}
