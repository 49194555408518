@use '../../../styles/theme';
@use '../../../styles/mixins';

.wrapper {
    @include mixins.space-vertical(8px);
}

.heading {
    padding: 12px 0;
    font-size: 1.6em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: theme.$text-mid-gray;
}

.list {
    @include mixins.space-vertical(8px);
}

.listItem {
    display: flex;
    padding: 19px 16px;
    background: theme.$informative-empty-state-background;
    border: 1px solid theme.$informative-empty-state-border-color;
    border-radius: 4px;
}

.itemIcon {
    width: 32px;
    height: 32px;
    margin-inline-end: 24px;
}

.itemContent {
    font-size: 1.4em;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: theme.$text-dark-gray;
}

.itemHeader {
    font-weight: 600;
}

.itemDescription {
    color: theme.$text-mid-gray;

    strong {
        font-weight: 600;
        color: theme.$text-dark-gray;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            margin-left: 1.27rem;
        }
    }
}
