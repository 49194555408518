@use '../../../../styles/theme';
@use '../../../../styles/mixins';

@value button from "../Actions/Thumbs/ThumbAction.module.scss";
@value wrapper as answer from "../../../Answers/Answer/Answer.module.scss";
@value footer as previewModalFooter from "../../../Modals/Preview/Footer/PreviewModalFooter.module.scss";

%wrapperExtend {
    @include mixins.space(10px);

    display: flex;
    justify-content: flex-end;
    visibility: hidden;

    & > .button {
        width: 16px;
    }
}

.wrapper {
    @extend %wrapperExtend;

    .answer:hover & {
        visibility: visible;
    }
}

.wrapperWebMode {
    @extend %wrapperExtend;

    @include mixins.breakpoint('large') {
        .answer:hover & {
            visibility: visible;
        }
    }
}

.action {
    @include mixins.thumb-icon(16px);

    .previewModalFooter & {
        @include mixins.thumb-icon(20px);
    }
}

.disabled {
    cursor: not-allowed;
    opacity: 0.3;
    width: 16px;

    .previewModalFooter & {
        width: 20px;
    }
}
