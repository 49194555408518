@use '../../../../styles/theme';
@use '../../../../styles/mixins';

$option-height: 24px;
$options-margin: 8px;
$menu-padding-y: 8px;
$menu-max-height: calc(5 * $option-height + 2 * $options-margin + 2 * $menu-padding-y);

.multiValue {
    &#{&} {
        flex-direction: row-reverse;
        color: theme.$chip-label-blue;
        background-color: theme.$chip-blue;
    }
}

.multiValueLabel {
    &#{&} {
        color: inherit;
        padding-block: 3px;
        padding-inline-start: 3px;
        padding-inline-end: 6px;
    }
}

.multiValueRemove {
    &#{&} {
        color: inherit;
        background-color: inherit;

        &:hover {
            color: theme.$chip-label-blue-hover;
            background-color: inherit;
        }
    }
}

.indicatorSeparator {
    display: none;
}

.option {
    width: 100%;
    height: $option-height;
    padding: 0 8px;
    display: flex;
    gap: 16px;
    align-items: center;
    font-size: 1.2em;
    font-weight: 500;
    color: theme.$text-light-gray;
    background-color: theme.$surface-primary;
    transition: all 0.3s ease-in;

    &:hover {
        cursor: pointer;
        color: theme.$link-primary;
        background-color: theme.$link-hover-light-blue;
    }
}

.createOptionLabel {
    font-weight: 700;
    color: theme.$link-primary;
}

.valueContainer {
    &#{&} {
        font-size: 1.2em;
        font-weight: 500;
        background-color: theme.$surface-primary;
    }
}

.container {
    min-height: 32px;
    box-sizing: border-box;
}

.indicatorsContainer {
    &#{&} > * {
        padding: 6px 8px;

        &,
        &:hover {
            color: theme.$text-light-gray;
            cursor: pointer;
        }
    }
}

.control {
    &#{&} {
        min-height: 32px;
        border-width: 2px;
        border-color: theme.$border-blue-gray;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);

        &[aria-disabled='true'] {
            opacity: 0.4;

            & > * {
                background-color: hsl(0, 0%, 95%);
            }
        }

        &:has(input[aria-invalid='true']) {
            border-color: theme.$button-primary-outlined-warning;
        }

        &:focus-within {
            border-color: theme.$hover-blue;
        }
    }
}

.menu {
    &#{&} {
        width: 100%;
        padding: 0 4px;
        box-shadow: 0 0.3px 5.5px 0 #00000007, 0 0.9px 18px 0 #0000000b, 0 4px 80px 0 #00000012;
    }
}

.menuList {
    &#{&} {
        overflow-y: auto;
        max-height: $menu-max-height;
        padding-top: $menu-padding-y;
        padding-bottom: $menu-padding-y;
        @include mixins.space-vertical($options-margin);
    }
}
