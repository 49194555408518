@use '../../../styles/theme';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;

    background: theme.$link-primary;
    color: theme.$surface-primary;

    font-size: 1.3em;
    font-weight: 500;
    line-height: 19px;

    padding: 16px 32px 16px 16px;
    margin-top: -(theme.$answers-list-padding);
    margin-right: -(theme.$answers-list-padding);
    margin-left: -(theme.$answers-list-padding);
    position: relative;
}

.close {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
}

.markdown > :is(h1, h2, h3, h4, h5, h6) {
    all: revert;
}
