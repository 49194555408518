@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

.wrapper {
    @include mixins.space-vertical(12px);

    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    min-width: 0;
}

h3.title {
    @include mixins.link;

    color: theme.$text-blue;
}
