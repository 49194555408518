@use '../../../styles/theme';
@use '../../../styles/mixins';

$source-height: 20px;
$source-width: 20px;
$source-gap: 4px;
$smart-filters-width: 45px;

$minimum-count-visible-popup-mode: 6;
$minimum-count-visible-sidebar-mode: 3;

.wrapper {
    display: flex;
    flex-direction: row;
    gap: $source-gap;
    width: 100%;
}

.sources {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    gap: $source-gap;
}

.filterButtonWrapper {
    display: flex;
    justify-content: flex-start;
    flex: 1;
}

.filterButton {
    cursor: pointer;
    flex: 0 0 $source-width;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: theme.$border-source-collapse;
    border: 1px solid theme.$border-source-collapse;
    border-radius: 4px;
    font-weight: 700;
    font-size: 0.67em;
    line-height: 10px;
    color: theme.$text-light-gray;
    height: $source-height;
    width: $source-width;
    position: relative;

    &:hover {
        background-color: theme.$background-source-collapse-hover;
        color: theme.$link-primary;
    }
}

.indicationBadge {
    position: absolute;
    top: -12px;
    right: -12px;
    height: 20px;
    width: 20px;
    background-color: theme.$badge-background;
    border: 2px solid theme.$badge-text;
    color: theme.$badge-text;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 700;
}

:export {
    sourceHeight: $source-height;
    sourceWidth: $source-width;
    sourceGap: $source-gap;
    smartFiltersWidth: $smart-filters-width;
}
