@use '../../../styles/theme';

.wrapper {
    cursor: pointer;
    display: flex;
    gap: 16px;
    color: theme.$blue-gray-900;
    background-color: theme.$background-light-grey;
    border: 1.5px solid theme.$blue-gray-200;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 8px;
    height: 100%;
}

.opened {
    color: theme.$blue-gray-600;
    border-color: theme.$blue-gray-600;
    background-color: #f2f7ff;
}

.dateRange {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-weight: 500;
    min-width: 195px;
}

.date {
    flex: 1;
    white-space: nowrap;
}

.dirty {
    font-weight: 700;
}

.icon {
    display: flex;
    align-items: center;
    flex: 0;
}

.customActions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
