@use '../../../../styles/theme';
@use '../../../../styles/mixins';

%extendableItemWrapper {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 8px;
}

.list {
    @include mixins.space-vertical(4px);

    list-style: none;
    margin: 0;
    padding: 8px 24px 0;
}

.maxSelectedLabel {
    color: theme.$text-mid-gray;
    font-weight: 400;
    padding: 8px 0 0 24px;
}
