@use 'answers-core/src/styles/theme';

.area {
    background-color: theme.$drag-and-drop-background-color;
    top: 0;
    left: 0;
    color: white;
    padding: 20px;
    position: fixed;
    z-index: map-get(theme.$zIndex, max);
    width: 100%;
    height: 100%;
    font-size: 2.5em;
    font-weight: 500;
    line-height: 32px;

    .title {
        padding: 20px;
        border: 2px dashed white;
        border-radius: 10px;
        height: 100%;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
