@use '../../../styles/theme';

.rankDescription {
    display: flex;
    padding-block: 6px;
    color: theme.$text-control;

    svg {
        flex-shrink: 0;
        margin-inline-end: 16px;
    }

    font-size: 1.1em;
    line-height: 1.55;
}

.description {
    display: flex;
    flex-direction: column;
}

.competitionEnds {
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 8px;
    border-radius: 4px;
    background: theme.$background-light-grey;
    font-weight: 500;
    color: theme.$blue-gray-600;

    svg {
        flex-shrink: 0;
    }
}

.learnMore {
    display: inline-block;
    cursor: pointer;
    max-width: fit-content;
    font-size: 0.85em;
    line-height: 1.75em;
    font-weight: 700;
    color: theme.$text-light-gray;
    white-space: nowrap;
    text-decoration: underline;
}
