@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

.wrapper {
    @include mixins.space-vertical(16px);
    border-radius: 4px;
    padding: 12px;

    &:focus {
        outline: 1px solid theme.$text-mid-gray;
    }

    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 15px rgba(0, 0, 0, 0.05);
}

.footer {
    display: flex;
    align-items: center;
}

.spacer {
    flex-grow: 1;
}

.icon {
    img {
        margin: 0;
        width: 20px;
        height: 20px;
    }
}
