@use '../../../styles/theme';

.banner {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 2px solid theme.$border-notification-banner;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.close {
    display: flex;
    line-height: 12px;
    position: absolute;
    right: 8px;
    top: 8px;
}

.image {
    display: block;
    max-height: 112px;
    height: 100%;
    width: auto;
}

.haveLink {
    cursor: pointer;
}
