@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

.formControl {
    label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 0;
    }

    .placeholder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-radius: 4px;
        border: 1px solid;
        border-color: theme.$border-light-gray;
        background: white;
        box-shadow: theme.$iconed-file-picker-box-shadow;
        color: theme.$blue-text;
        font-size: 1.4em;
        padding: 7px 15px 7px 15px;
    }

    .fileItem {
        display: flex;
        gap: 5px;
    }

    .deleteFile {
        display: flex;
        align-items: center;
    }

    .fileName {
        @include mixins.overflow-ellipsis();
    }

    .fileList {
        margin-top: 10px;
    }
}
