@use '../../../../styles/theme';

.datesWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding-top: 16px;
}

.chip {
    color: theme.$blue-gray-600;
    background-color: theme.$background-light-grey;
    padding: 7px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    transition: border-color 0.15s ease;

    &:hover {
        cursor: pointer;
        border-color: theme.$blue-gray-600;
    }
}

.selectedChip {
    color: theme.$blue-50;
    background-color: theme.$link-primary;
}
