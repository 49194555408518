@use '../../../styles/theme';
@use '../../../styles/mixins';

.popoverContent {
    max-width: 320px;
    white-space: normal;
}

.points {
    @include mixins.space(5px);

    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    color: theme.$link-primary;
    font-weight: 500;
    font-size: 0.92em;
    line-height: 13px;
    white-space: nowrap;
    padding: 4px 8px;
    border-radius: 4px;
    min-height: 24px;
    background-color: transparent;
    transition: background-color ease-in 0.2s;

    :global(:host([data-container-size='small'])) & {
        display: none;
    }

    &:hover {
        background-color: theme.$link-hover-light-blue;
        cursor: pointer;
    }
}

.tooltipActive {
    background-color: theme.$link-hover-light-blue;
}

.lottie {
    width: 144px;
    height: 144px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-35%, -50%);
}
