@use '../../../../../styles/theme';

.wrapper {
    padding: 15px;

    header {
        color: theme.$link-primary;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
    }

    main {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 16px;
        padding-top: 50px;
    }

    h1 {
        color: theme.$text-positive;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.15px;
        max-width: 70%;
    }

    p {
        color: theme.$text-light-gray;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        max-width: 80%;
    }
}

.hint {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 34px;
}
