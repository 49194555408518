@use '../../styles/theme';
@use '../../styles/mixins';

$border-width: 2px;
$border-radius: 8px;
$logo-size: 32px;
$ask-area-margin: 7px;
$wrapper-border-radius: $border-radius + $border-width;
$right-icon-size: 26px;

.relativeWrapper {
    position: relative;
}

.shadowWrapper {
    margin: -$border-radius;
    padding: $border-radius;

    &.suggestionsVisible {
        margin-bottom: 0;
        padding-bottom: 0;
        overflow: hidden;
    }
}

.wrapper {
    display: flex;
    background: theme.$surface-primary;
    box-sizing: content-box;
    border: $border-width solid theme.$border-ask-wrapper;
    border-radius: $wrapper-border-radius;
    min-height: $logo-size + $ask-area-margin * 2 + $border-width;

    .suggestionsVisible & {
        border-radius: $wrapper-border-radius $wrapper-border-radius 0 0;
        border-bottom: 0;
        padding-bottom: $border-width;
    }

    &.focused {
        border-color: theme.$border-ask-focus;
        box-shadow: 0 0 10px 4px theme.$border-ask-wrapper;
    }
}

.border {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    border-radius: $border-radius;
    background-color: theme.$surface-primary;
    border: 1px solid theme.$border-ask-focus;

    .suggestionsVisible & {
        position: relative;
        border-bottom: 0;
        border-radius: $border-radius $border-radius 0 0;
    }

    .focused & {
        border-color: theme.$surface-primary;
    }
}

.visible {
    margin: $ask-area-margin;
    display: flex;
    flex: 0 0 $logo-size;
    align-items: center;
    position: relative;
}

.hidden {
    display: flex;
    visibility: hidden;
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
}

.hiddenTextArea {
    overflow: hidden;
}

.search {
    @include mixins.custom-scroll();
    resize: none;
    z-index: map-get(theme.$zIndex, base) + 1;
    background-color: transparent;

    &.withRightIcon {
        padding-inline-end: 40px;
    }

    &.notEmpty {
        padding-inline-end: 28px;

        &.withRightIcon {
            padding-inline-end: 64px;
        }
    }
}

.logo {
    position: absolute;
    z-index: map-get(theme.$zIndex, base) + 1;
    height: $logo-size;
    width: $logo-size;

    & > svg {
        height: inherit;
        width: inherit;
    }

    & + .search {
        padding-inline-start: 48px;
    }
}

.suggestionsShadowWrapper {
    background: transparent;
    position: absolute;
    left: -$border-radius;
    right: -$border-radius;
    top: 100%;
    padding: 0 $border-radius $border-radius;
    overflow: hidden;
    z-index: map-get(theme.$zIndex, base) + 1;
}

.suggestionsWrapper {
    background: theme.$surface-primary;
    border: $border-width solid theme.$border-ask-focus;
    border-top: 0;
    border-radius: 0 0 $wrapper-border-radius $wrapper-border-radius;
    padding: $border-radius;
    box-shadow: 0 0 10px 4px theme.$border-ask-wrapper;
}

.suggestions {
    .item {
        display: flex;
        align-items: center;
        color: theme.$text-dark-gray;
        font-size: 1.4em;
        line-height: 17px;
        cursor: pointer;
        justify-content: space-between;
        padding: 11.5px 9.5px 11.5px 11.5px;
        gap: 5px;

        &:hover {
            @include mixins.suggestion-opacity();

            .deleteBtn {
                opacity: 1;
                padding-right: 5px;
            }
        }

        .searchAndSuggestionWrapper {
            display: flex;
            align-items: center;
            min-width: 0;
            flex: 1;
        }

        .suggestionText {
            @include mixins.overflow-ellipsis();
        }
    }

    .chosenItem {
        @include mixins.suggestion-opacity();
    }
}

.searchIcon {
    width: 16.79px;
    height: 14.54px;
    margin-right: 10px;
}

.deleteBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    opacity: 0;
    transition: opacity 0.25s ease-in-out, padding-right 0.25s ease-in-out;
}

.deleteIcon {
    color: theme.$action-bar-button-color;
    width: 12px;
    height: 12px;
}

.clearIcon {
    cursor: pointer;
    color: theme.$text-suggestion;
    position: absolute;
    right: 12px;
    top: 50%;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
    z-index: map-get(theme.$zIndex, base) + 1;

    &:hover {
        color: theme.$action-bar-button-color;
    }
}

button.rightIcon {
    cursor: pointer;
    color: theme.$text-suggestion;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    z-index: map-get(theme.$zIndex, base) + 1;

    &:hover {
        color: theme.$action-bar-button-color;
    }

    & ~ .clearIcon {
        right: 44px;
    }
}
