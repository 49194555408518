@use '../../../styles/mixins';

.footer {
    composes: footer from '../Modals.module.scss';
}

.content {
    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        padding: 35px 20px 105px;
    }

    .message {
        font-size: 20px;
        text-align: center;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.15px;
    }

    .link {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15px;
    }

    .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15px;
    }
}
