@use 'answers-core/src/styles/mixins';
@use 'answers-core/src/styles/theme';

:export {
    annotateLabelTextWhite: theme.$text-white;
    annotateLabelRed: theme.$annotate-label-red;
    annotateLabelOrange: theme.$annotate-label-orange;
    annotateLabelYellow: theme.$annotate-label-yellow;
    annotateLabelLightGreen: theme.$annotate-label-light-green;
    annotateLabelGreen: theme.$annotate-label-green;
    annotateLabelBlue: theme.$annotate-label-blue;
    annotateLabelLightGrey: theme.$annotate-label-light-grey;
    annotateLabelPrevChecked: theme.$annotate-label-prev-checked;
    annotateLabelGradientRedOrange: theme.$annotate-label-gradient-red-orange;
    annotateLabelGradientOrangeYellow: theme.$annotate-label-gradient-orange-yellow;
    annotateLabelGradientYellowLightGreen: theme.$annotate-label-gradient-yellow-light-green;
    annotateLabelGradientLightGreenGreen: theme.$annotate-label-gradient-light-green-green;
}

.radioWrapper {
    @include mixins.space(4px);
    display: flex;
    align-items: center;
}

.radioLabel {
    cursor: pointer;
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
}

.radioViewInput {
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50%;
    color: theme.$text-positive;
    background-color: theme.$annotate-label-light-grey;

    transition: background-color, color 0.3s ease-out;
}

.decimalVieInput {
    width: 8px;
    height: 8px;
}

%extendableGroupWrapper {
    max-height: 32px;
    background-color: theme.$surface-primary;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.radioGroup {
    @include mixins.space(4px);
    @extend %extendableGroupWrapper;

    display: flex;
    align-items: center;
    padding: 4px 5px;
    border-radius: 24px;
}

.naWrapper {
    @extend %extendableGroupWrapper;

    padding: 4px;
    border-radius: 32px;
}

.previouslyChecked {
    background-color: theme.$annotate-label-prev-checked;
}
