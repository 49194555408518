@use '../../styles/theme';

.wrapper {
    :global(div[data-popper-placement^='top']) & {
        div[data-popper-arrow-inner='true'] {
            border-color: theme.$text-tooltip;
            border-style: solid;
            border-bottom-width: 1px;
            border-right-width: 1px;
        }
    }

    :global(div[data-popper-placement^='bottom']) & {
        div[data-popper-arrow-inner='true'] {
            border-color: theme.$text-tooltip;
            border-style: solid;
            border-top-width: 1px;
            border-left-width: 1px;
        }
    }
}

:export {
    backgroundColor: theme.$input-background;
}
