@use '../../../../styles/theme';
@use '../../../../styles/mixins';

$line-height: 16px;
$button-size: 20px;
$selector-gap: 8px;
$divider-total-width: 2px + 2 * $selector-gap;

$right-elements-length: 3 * $button-size + $selector-gap + $divider-total-width + 2 * $selector-gap; // two more gaps size for space around the all right element content

.wrapper {
    @include mixins.space(10px);

    border-bottom: 2px solid theme.$border-gray;
    display: flex;

    &:global(.chakra-modal__header) {
        height: auto;
    }
}

.title {
    width: 100%;
    display: flex;
    gap: 6px;
}

.content {
    position: relative;
    width: 100%;
    display: flex;
    padding-top: 4px; // to visually align the title with the search button, don't use 'align-items: center;'
    min-width: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: $line-height;
}

.titleText {
    min-width: 0;
    flex-grow: 1;
    display: inline-block;
    overflow: hidden;
}

.truncatedText {
    word-break: break-all;
    max-height: calc(2 * #{$line-height});
}

.collapse {
    position: absolute;
    white-space: nowrap;
    appearance: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    width: max-content;
    height: max-content;
    bottom: 0;
    background-color: #f6faff;
    font-weight: 700;
    color: theme.$text-light-gray;

    &:before {
        content: '...';
        box-shadow: -6px 0px 4px -1px #f6faff;
    }

    &:dir(ltr) {
        left: 100%;
        transform: translateX(-100%);
    }

    &:dir(rtl) {
        left: 0;
    }
}

.searchIconButton {
    min-width: 24px;
    width: 24px;
    height: 24px;
}

.selector {
    grid-area: auto;
    justify-self: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    gap: $selector-gap;
}

.search {
    flex: 1 1 auto;

    input {
        padding: 6px $right-elements-length 6px 12px;
        color: theme.$text-light-gray;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
}

.divider {
    margin: 0 $selector-gap;
    height: 50%;
    border-style: solid;
    border-left-width: 2px;
    border-color: #dbdcde;
}

@mixin button {
    cursor: pointer;
    width: $button-size;
}

.prevButton {
    @include button;

    transform: rotate(90deg);
}

.nextButton {
    @include button;

    transform: rotate(-90deg);
}

.prevMatchButton {
    @include button;
    transform: rotate(90deg);
}

.nextMatchButton {
    @include button;

    transform: rotate(-90deg);
}

.searchButton {
    @include button;
}

.zoomButton {
    @include button;

    color: theme.$text-light-gray;
}

:export {
    rightElementLength: $right-elements-length;
}
