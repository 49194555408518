.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 16px;
}

.textField,
.richTextEditor {
    max-width: 800px;
}

.richTextEditor {
    min-height: 0;
    flex: 1;
}

.accessLevel {
    max-width: 216px;
}
