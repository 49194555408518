@use 'answers-core/src/styles/mixins';

.body {
    text-align: center;
    margin: 16px 0 24px;
    font-weight: 500;
}

.footer {
    @include mixins.space(8px);

    justify-content: flex-end;
}
