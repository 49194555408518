@use '../../styles/theme';
@use '../../styles/mixins';

.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.header {
    display: flex;
    flex-direction: column;
    flex: 0 0 206px;
    background: theme.$gradient-main;
}

.logo {
    display: flex;
    flex: 0 0 146px;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 10px;
}

.bevel {
    height: 2px;
    background: theme.$bevel-gradient;
}

.tagline {
    display: flex;
    flex: 0 0 48px;
    align-items: center;
    justify-content: center;
}

.footer {
    @include mixins.separator(15px);

    display: flex;
    flex: 0 0 15px;
    justify-content: center;
    color: theme.$text-light-gray;
    font-weight: 500;
    font-size: 1.2em;
    line-height: 15px;
    margin-bottom: 16px;
}
