@use '../../../styles/theme';
@use '../../../styles/mixins';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    background-color: theme.$surface-primary;
    height: 100%;
}

.error {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: theme.$text-error;
    margin-top: 160px;
    position: absolute;
    text-align: center;
}

.resetLink {
    @include mixins.link;

    color: theme.$text-blue;
}
