@use '../../../styles/theme';
@use '../../../styles/mixins';

.wrapper {
    @include mixins.toast;

    border: 1px solid theme.$border-toast--warning;
    background: theme.$surface-toast--warning;
    color: theme.$text-toast--warning;
}
