@use '../../../styles/theme';

.wrapper {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid theme.$border-source-wrapper;
    overflow: hidden;

    &:hover {
        transform: scale(120%);
    }
}

.icon {
    width: 16px;
    height: 16px;
    border-radius: 2px;
}

.disabled {
    filter: grayscale(100%);
    opacity: 0.35;
}
