@use '../../../styles/theme';

.wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.animation {
    height: 130px;
    width: 130px;
}
