@use '../../../styles/theme';
@use '../../../styles/mixins';

.wrapper {
    @include mixins.toast;

    border: 1px solid theme.$text-light-gray;
    background: theme.$text-blue-gray;
    color: theme.$text-mid-gray;
}
