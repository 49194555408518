@use '../../../../../styles/theme';
@use '../../../../../styles/mixins';

.editor {
    overflow: hidden;
    min-height: 0;
    height: 100%;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    white-space: normal;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);
    border-width: 2px;
    border-style: solid;
    border-color: theme.$border-blue-gray;

    &:focus-within {
        border-color: theme.$hover-blue;

        & > div:global(.ql-snow) {
            border-color: theme.$hover-blue;
        }
    }

    &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
        background-color: theme.$background-disabled;
    }

    &.error {
        border-color: theme.$button-primary-outlined-warning;
    }

    & > div:global(.ql-snow) {
        border: 0;

        &:global(.ql-toolbar) {
            border-bottom: 1px solid theme.$border-blue-gray;
            background-color: theme.$surface-primary;

            & span:global(.ql-header.ql-picker) {
                font-size: 10px;
            }
        }

        &:global(.ql-container) {
            overflow-y: hidden;
        }

        & > div:global(.ql-editor) {
            margin: 6px 0;
            padding: 0 12px;

            @include mixins.custom-scroll();
        }
    }

    ul,
    ol {
        margin-block: 0.5em;
    }

    ul {
        padding-inline-start: 1.8em;
    }

    ol {
        padding-inline-start: 2em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-block: 0.85em;
    }

    p {
        margin-block: 0.55em;
    }
}
