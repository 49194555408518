:global(.chakra-modal__footer).footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    gap: 16px;
}

.left {
    flex: 0 0 auto;
}

.right {
    flex: 1;
    justify-content: flex-end;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}
