@use '../../../styles/mixins';
@use '../../../styles/theme';

.wrapper {
    @include mixins.answer-wrapper();
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.content {
    display: flex;
    gap: 15px;
}

.search {
    background: theme.$direct-answer-search-background;
    border: theme.$direct-answer-search-border;
}

.summarize {
    background: theme.$direct-answer-summarize-background;
    border: theme.$direct-answer-summarize-border;
}

.rephrase {
    background: theme.$direct-answer-rephrase-background;
    border: theme.$direct-answer-rephrase-border;
}

.custom {
    border: theme.$direct-answer-search-border;
}

.footer {
    display: flex;
    padding-top: 6px;
    width: 100%;
    gap: 5px;
}

.alert {
    position: absolute;
    top: 8px;
    right: 8px;
}

.attributions {
    margin-block: 6px 12px;
}
