@use '../../../styles/theme';
@use '../../../styles/mixins';

.wrapper {
    position: relative;
    min-width: 70px;
    max-width: 100px;
    padding: 2px 12px;
    border-radius: 100px;
    background-color: #fff;

    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease-in-out;
}

.wrapper:hover {
    max-width: 100%;
    padding-right: 26px;

    .icon {
        opacity: 1;
        right: 8px;
        transform: translate(0, -50%);
    }
}

.tag {
    text-align: center;
    color: #1565c0;
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 0.15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.icon {
    opacity: 0;
    position: absolute;
    display: inline-flex;
    width: 14px;
    height: 14px;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    transition: opacity 0.3s ease-in;

    &:hover {
        opacity: 1;
        right: 8px;
        transform: translate(0, -50%);
    }
}
