@use '../../../../styles/theme';
@use '../../../../styles/mixins';

.action {
    flex: 0 0 auto;
    background: none;
    border: unset;
    appearance: none;
    color: theme.$link-primary;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    font-size: 12px;
    font-weight: 600;

    &:hover {
        color: theme.$hover-blue;
    }

    &:active {
        color: theme.$link-hover-light-blue;
    }
}

.accordionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 4px 8px;
}

.arrowIcon {
    transition: transform 0.3s ease;
    margin-inline-end: 4px;
}

.rotate {
    transform: rotate(180deg);
}

.arrowIconWrapper {
    padding: 2px;
    border-radius: 50%;
    transition: background-color 0.15s ease;

    &:hover {
        background-color: theme.$hover-gray;
        cursor: pointer;
    }
}

.titleWrapper {
    display: flex;
    align-items: center;
}

.title {
    font-size: 14px;
    font-weight: 700;
    color: theme.$blue-gray-900;
    white-space: nowrap;
}

.titleContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
}
