@use '../../../styles/mixins';
@use '../../../styles/theme';

.wrapper {
    @include mixins.placeholder-wrapper(33px);
}

.results {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.heading {
    margin-top: 13px;
}

.question {
    margin-top: 6px;
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
