@use '../../../styles/mixins';

.footer {
    composes: footer from '../Modals.module.scss';
}

.content {
    .body {
        padding: 18px 24px 72px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .textarea {
        padding: 6px 12px;
    }
}
