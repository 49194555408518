@use '../../../styles/theme';
@use '../../../styles/mixins';

.wrapper {
    @include mixins.answer-wrapper();
    flex-direction: column;
    justify-content: space-between;

    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 15px rgba(0, 0, 0, 0.05);
}

.gray {
    color: theme.$text-light-gray;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    min-height: 32px;
}

.wrapperWithTopBar {
    padding-top: 10px;
}

.wrapperWebApp {
    cursor: pointer;
}
