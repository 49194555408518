@use '../../../styles/theme';

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    border-radius: 50%;
    height: theme.$avatar-size;
    width: theme.$avatar-size;
    min-width: theme.$avatar-size;

    background-color: theme.$user-background;
    color: theme.$surface-primary;
    font-size: 1em;
    font-weight: 600;
    line-height: 24px;
}
