@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

.wrapper {
    @include mixins.space(8px);

    display: flex;
    justify-content: flex-end;
}

.submitAnnotationWrapper {
    position: sticky;
    bottom: 8px;
    z-index: map-get(theme.$zIndex, base);
}

.submitAnnotation {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 8px;
    color: theme.$text-white;
    background-color: theme.$annotate-submit-blue;

    & > span {
        margin: 0 12px;
        font-size: 13px;
        line-height: 21px;
        font-weight: 600;
    }
}

.annotateButtons {
    @include mixins.space(8px);
}

.onSuccess {
    background-color: theme.$annotate-submit-green;
}

.spacer {
    flex-grow: 1;
}
