@use '../../../../styles/theme';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 4px;
}

.controls {
    display: flex;
    align-items: center;
    gap: 4px;
}

.offsetButtons {
    flex: 0;
    display: flex;

    button {
        color: theme.$blue-gray-800;
        min-width: 24px;
    }
}

.monthYear {
    flex: 1;
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
}

.days {
    display: grid;
    grid-template-columns: repeat(7, 32px);
    grid-template-rows: 30px repeat(6, 1fr);
    row-gap: 2px;
}

.weekDay {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.notCurrentMonth {
    color: theme.$blue-gray-400;
}

.now {
    border: 1px solid theme.$blue-gray-600;
}

.rangeStart,
.rangeEnd {
    color: #000;

    &,
    &:hover {
        background-color: theme.$calendar-selected-date-background-color;
    }
}

.rangeStart:not(:nth-child(7n)) {
    border-radius: 4px 0 0 4px;
}

.rangeEnd:not(:nth-child(7n + 1)) {
    border-radius: 0 4px 4px 0;
}

.rangeStartEnd {
    color: #000;

    &,
    &:hover {
        background-color: theme.$calendar-selected-date-background-color !important;
    }
}

.inRange {
    background-color: theme.$blue-50;
    border-radius: 0 !important;
    color: #000;

    &:nth-child(7n + 1) {
        border-radius: 4px 0 0 4px;
    }

    &:nth-child(7n) {
        border-radius: 0 4px 4px 0;
    }
}

.willBeInRange {
    border-radius: 0 !important;
    border: 1px dashed theme.$calendar-selected-date-background-color !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;

    &:nth-child(7n + 1) {
        border-radius: 4px 0 0 4px !important;
        border-left-width: 1px !important;
    }

    &:nth-child(7n) {
        border-radius: 0 4px 4px 0 !important;
        border-right-width: 1px !important;
    }
}

.actions {
    display: flex;
    justify-content: space-between;
}

.today {
    color: theme.$link-primary;
    text-transform: none;

    &,
    &:hover {
        background-color: transparent;
    }
}

.dateButton {
    &:hover {
        background-color: theme.$blue-gray-100;
    }
}
