$image-width: 15px;
$image-height: 15px;

.wrapper {
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    align-items: center;
}

.text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 16px;
}

.icon {
    width: 24px;
    aspect-ratio: 1;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    display: flex;
    flex: 0 0 24px;
}

.image {
    margin: auto;
    display: block;
    width: 100%;
    height: auto;
    max-width: $image-width;
    max-height: $image-height;
    object-fit: cover;
}

:export {
    imageWidth: $image-width;
    imageHeight: $image-height;
}
