@use '../../../../styles/theme';

.wrapper {
    background: theme.$background-alert-blue;
    border: 1px solid theme.$border-alert-blue;
    border-radius: 8px;
    color: white;
    font-weight: 400;
    font-size: 1.4em;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 20px 56px 0;
    padding: 1em;

    svg {
        flex: 0 0 32px;
    }
}

.textWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
}
